import React from 'react'
import Img from 'gatsby-image'
import PageTitle from 'components/page-title'

import './blog-header.scss'

const BlogHeader = ({ title, date, author, sizes }) => (
  <div className="blog-header">
    <PageTitle title={title} />
    <Img sizes={sizes} />
    <div className="content">
      <h1>{title}</h1>
      <p>Posted {author && `by ${author}`} on <time dateTime={date}>{date}</time></p>
    </div>
  </div>
)

export default BlogHeader
