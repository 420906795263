import React from 'react'
import { graphql } from 'gatsby'

import Layout from 'components/layout'
import BlogHeader from 'components/blog-header';

const Articles = ({ data }) => {
  const article = data.article.frontmatter
  return (
    <Layout>
      <BlogHeader
        sizes={article.image.childImageSharp.fluid}
        title={article.title}
        date={article.date}
        author={article.author}
      />
      <div className="l-article">
        <section 
          dangerouslySetInnerHTML={{ __html: data.article.html }} 
        />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    article: markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        author
        category
        tags
        image {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default Articles